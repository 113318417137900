/**
 * Created by glenne on 6/10/2017.
 */
import React, { FC, useRef, useState, useMemo, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Util from './Util';
import { setLapEditorInfo } from '../forms/LapEditor';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { FormControlLabel, Switch, TableCell, TableCellProps } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as Names from './Names';
import fire from '../fire';
import { Lap } from './CrewTimerTypes';
import { KeyMap } from './KeyMap';
import { useTranslation } from 'react-i18next';
import TimeSystemSelection from './TimeSystemSelection';
import {
  useOfficialEntries,
  useShowAnalysis,
  useShowClockTimes,
  useShowStopwatchTimes,
  useStopwatchConfig,
  useIsEditable,
  useShowScheduleOrder,
  useSelectedRegatta,
} from './RegattaState';
import { milliToString, timeDiff, timeToMilli } from './TimeUtil';
import { v1 as uuidv1 } from 'uuid';
import { Entry } from './Entry';
import { setDialogConfig } from './ConfirmDialog';
import EditIcon from '@mui/icons-material/Edit';
import { useEventResult, useRegattaInfo } from './UseResults';
import { setContextAnchor } from './TimeContextMenu';
import { useSearchText } from './EventFilter';

Util.setFirebase(fire);

const evenColor = '#f0f0f0';
const oddColor = '#ffffff';

interface EditableTableCellProps {
  ts: string;
  wp: string;
  row: Entry;
}

export const ClickableTableCell: React.FC<TableCellProps & EditableTableCellProps> = (props) => {
  const anchor = useRef<typeof TableCell>(null);
  const onClick = (e: any) => {
    e.preventDefault();
    const { ts, wp, row } = props;
    setContextAnchor({ ts, wp, row, anchor: anchor.current, open: true });
  };
  return <TableCell {...props} ref={anchor} onClick={onClick} onContextMenu={onClick} />;
};

export const SimpleTableCell: React.FC<TableCellProps & EditableTableCellProps> = (props) => {
  return <TableCell {...props} />;
};

export const DnDTableCell: React.FC<TableCellProps & EditableTableCellProps> = (props) => {
  const dndRef = useRef();
  const [regatta] = useSelectedRegatta();
  const { t } = useTranslation();

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'ts',
      canDrag: () => !!props.ts,
      item: { wp: props.wp, row: props.row, ts: props.ts },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [props.ts]
  );
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'ts',
      drop: (item: EditableTableCellProps) => {
        setDialogConfig({
          handleConfirm: () => {
            // console.log(`Update Event ${props.row.EventNum}-${props.row.Bow} ${props.wp} with ${item.ts}`);
            const lap: Lap = {
              keyid: '',
              uuid: uuidv1(),
              SequenceNum: 1,
              AdjTime: '',
              Bow: props.row.Bow,
              EventNum: props.row.EventNum,
              Crew: '',
              CrewAbbrev: '',
              Event: '',
              EventAbbrev: '',
              Time: item.ts,
              Gate: props.wp === 'S' ? 'S' : props.wp === 'F' ? 'F' : `G_${props.wp}`,
              Place: 0,
              Stroke: '',
            };
            // console.log(`lap=${JSON.stringify(lap, null, 2)}`);
            Util.storeLap(regatta, lap).catch(() => {});
          },
          title: `Replace timestamp`,
          message: `Update Event ${props.row.EventNum} ${t('Bow')} ${props.row.Bow} ${props.wp} ${
            props?.ts || ''
          } with ${item.ts}`,
          button: 'Replace',
          showCancel: true,
        });
      },
      canDrop: (item) => item?.row !== props.row || item?.wp !== props.wp,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [props.row]
  );
  const onClick = (e: any) => {
    e.preventDefault();
    const { ts, wp, row } = props;
    setContextAnchor({ ts, wp, row, anchor: dndRef.current, open: true });
  };
  const { style, ...restofprops } = props;
  drag(drop(dndRef));
  return (
    <TableCell
      ref={dndRef}
      onClick={onClick}
      onContextMenu={onClick}
      {...restofprops}
      style={{ ...style, backgroundColor: isDragging ? 'violet' : isOver ? 'pink' : style?.backgroundColor }}
    />
  );
};
export const ClickableRow = (props) => {
  // Destructure props to keep the expected MUI TableRow props
  // while having access to the rowData prop
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  const { rowData, ...restProps } = props;
  return (
    <TableRow
      {...restProps}
      // onMouseDown={()=> console.log('clicked', props.rowData)}
    >
      {props.children}
    </TableRow>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  info: {
    backgroundColor: '#fff2cc',
  },
  tableSuperRow: {
    backgroundColor: '#e0e0e0',
  },
  tableSuperHeader: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontSize: '1em',
    fontWeight: 'bold',
    height: '30px',
    color: 'black',
    paddingLeft: '0.5em',
    textAlign: 'left',
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  tableSuperHeaderRight: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontSize: '1em',
    fontWeight: 'bold',
    height: '30px',
    color: 'black',
    paddingLeft: '0.5em',
    textAlign: 'right',
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  tableEventInfo: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontSize: '0.8em',
    lineHeight: '1.2em',
    fontWeight: 'normal',
    color: 'black',
    paddingLeft: '1em',
    textAlign: 'left',
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  TableHead: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontSize: '1em',
    fontWeight: 'bold',
    // height: "30px",
    color: 'black',
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
  },
  tableRow: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontSize: '1em',
    fontWeight: 'normal',
    height: '20px',
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
    paddingTop: '2px',
    paddingBottom: '2px',
    color: 'black',
  },
  headerRow: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontSize: '1em',
    fontWeight: 'bold',
    height: '25px',
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
    paddingTop: '2px',
    paddingBottom: '2px',
    color: 'black',
    backgroundColor: '#f0f0f0',
  },
};

const TimeTemplate = '00:00:00.000';
const BowTemplate = '000';
const PlaceTemplate = 'Place';

interface HighlightedTableCellProps {
  text: string;
  style: React.CSSProperties;
}

const HighlightedTableCell: React.FC<HighlightedTableCellProps> = ({ text, style }) => {
  const [searchText] = useSearchText();
  if (searchText && text.toLowerCase().includes(searchText)) {
    return (
      <TableCell style={style} sx={{ boxShadow: '0 0 10px 2px rgb(108, 125, 150)', borderRadius: '2px' }}>
        {text}
      </TableCell>
    );
  } else {
    return <TableCell style={style}>{text}</TableCell>;
  }
};

const RegularTableCell: React.FC<HighlightedTableCellProps> = ({ text, style }) => {
  return <TableCell style={style}>{text}</TableCell>;
};

interface EventTableProps {
  width: number;
  tableWidth: number;
  dimensions: KeyMap;
  regatta: string;
  eventNum: string;
  tableNumber: number;
}

const EventTable: FC<EventTableProps> = (props) => {
  const eventNum = props.eventNum;
  const anchorEl = useRef();
  const { t } = useTranslation();
  const [allOfficialEntries] = useOfficialEntries();
  const [officialTimes, setOfficialTimes] = useState(false);
  const [editable] = useIsEditable();
  const [regattaData] = useRegattaInfo();
  const [data] = useEventResult(eventNum);
  const [showAnalysis] = useShowAnalysis();
  const [showClockTimes] = useShowClockTimes();
  const [showStopwatchTimes] = useShowStopwatchTimes();
  const [stopwatchConfig] = useStopwatchConfig();
  const [showScheduleOrderProp] = useShowScheduleOrder();
  const [searchText] = useSearchText();
  const [, progressUpdate] = useState(0);
  const showScheduleOrder = editable && showScheduleOrderProp;

  const columnTitles = regattaData?.[Names.N_TITLES] || {};
  const dayList: string[] = regattaData?.[Names.N_DAY_LIST] || [];

  const WrappedTableCell = editable && showClockTimes ? DnDTableCell : SimpleTableCell;
  // const WrappedTableCell = editable && showClockTimes ? ClickableTableCell : SimpleTableCell;

  const getTitle = (name: string) => {
    if (name === 'Stroke/Cox') {
      return columnTitles.Stroke || `${t('Stroke')}/${t('Cox')}`;
    }
    return columnTitles[name] || t(name);
  };

  const onRowClick_ = (row) => (event: any) => {
    // This prevents ghost click.
    event.preventDefault();
    anchorEl.current = event.currentTarget;
    setLapEditorInfo({ anchor: anchorEl.current, entryInfo: row });
  };

  const showAllProp = Boolean(window.location.search) && window.location.search.indexOf('all=true') >= 0;
  const allWaypoints = editable || showAllProp;
  const onRowClick = editable ? onRowClick_ : () => () => {};
  const finished = data[Names.N_FINISHED];
  const official = data[Names.N_OFFICIAL];
  // const tzOffset = data[Names.N_TZ_OFFSET];
  const omitSelect = (!editable && regattaData?.[Names.N_RESULT_OMIT_COLS]) || [];
  const isSprint = (data[Names.N_RACE_TYPE] ? data[Names.N_RACE_TYPE] : regattaData?.[Names.N_RACE_TYPE]) === 'Sprint';
  const isInfoEvent = data[Names.N_RACE_TYPE]?.toLowerCase() === 'info';
  const provisionalTimes = !finished && !official;
  const showProgress = regattaData?.[Names.N_SHOW_PROGRESS] && !finished && !official;
  useMemo(() => {
    setOfficialTimes(!provisionalTimes);
  }, [provisionalTimes]);

  const entries = useMemo(() => {
    let rawEntries = data.entries || [];
    if (editable && rawEntries.length === 0) {
      rawEntries = [
        {
          AdjTime: '',
          [Names.N_EVENTNUM]: eventNum,
          [Names.N_BOW]: '1',
          [Names.N_CREW]: 'TBD',
          [Names.N_CREW_ABBREV]: 'TBD',
          [Names.N_EVENT_NAME]: 'Unlisted',
          [Names.N_EVENT_ABBREV]: '',
          Place: 0,
          Stroke: '',
        },
      ];
    }
    let entriesToShow = rawEntries;
    if (editable && showScheduleOrder) {
      // Make a copy as sort does inplace and we don't want to disturb the backing store
      entriesToShow = [...rawEntries].sort((a, b) => a[Names.N_INDEX] - b[Names.N_INDEX]);
    }

    if (!editable) {
      // Progressions will assign an 'Empty' crew to lanes with no entrant.  Filter these out.
      entriesToShow = entriesToShow.filter((e) => e.Crew !== 'Empty');
    }
    return entriesToShow;
  }, [editable, showScheduleOrder, data.entries, eventNum]);

  useEffect(() => {
    if (showProgress) {
      const timer = setInterval(() => progressUpdate((val) => val + 1), 100);
      return () => clearInterval(timer);
    }
  }, [showProgress]);

  if (regattaData === undefined || (!isInfoEvent && entries.length === 0)) {
    return <></>;
  }

  let tableMargin = Math.trunc(props.tableWidth * 0.02);
  if (tableMargin <= 12) tableMargin = 0;
  const tableWidth = props.tableWidth - tableMargin * 2;
  let omitCols = tableWidth < 512 ? 4 : tableWidth < 800 ? 2 : tableWidth < 1024 ? 1 : 0;

  const omitStroke = omitCols >= 1 || omitSelect.includes('Stroke');
  const omitCrew = omitSelect.includes('Crew');
  const omitPlace = omitSelect.includes('Place');
  const omitAdjust = omitSelect.includes('Adjust');
  const omitStart = omitCols >= 2 || omitSelect.includes('Start');
  const omitRaw = omitCols >= 3 || omitSelect.includes('Raw Time');
  const omitSplit = omitCols >= 4 || omitSelect.includes('Split');

  omitCols = 0;
  [omitStart, omitPlace, omitCrew, omitStroke, omitRaw, omitAdjust, omitSplit].forEach((omit) => {
    if (omit) {
      omitCols++;
    }
  });

  const timeWidth = props.dimensions[TimeTemplate].width;
  const placeWidth = omitPlace ? 0 : props.dimensions[PlaceTemplate].width;
  const bowWidth = props.dimensions[BowTemplate].width;

  // Place	[Crew]	Bow	[Stroke/Cox] Start RawTime	Adjust	Time	Split
  // Extra width given to crew and stroke columns
  const widthAvail =
    tableWidth -
    placeWidth -
    /* crew */
    bowWidth -
    /* stroke */
    (omitStart ? 0 : timeWidth) -
    (omitRaw ? 0 : timeWidth) -
    (omitAdjust ? 0 : timeWidth) -
    timeWidth - // Time
    (omitSplit ? 0 : timeWidth / 2);

  const strokeWidth = omitStroke ? 0 : Math.trunc(widthAvail * 0.3);
  const crewWidth = omitCrew ? 0 : widthAvail - strokeWidth;

  // console.log( 'tableWidth=' + tableWidth + ' widthAvail=' + widthAvail  );
  const rowStyle = {
    Place: Object.assign({}, styles.tableRow, {
      width: props.dimensions[PlaceTemplate].width,
    }),
    Crew: Object.assign({}, styles.tableRow, {
      width: crewWidth,
    }),
    Highlight: {
      boxShadow: '0 0 10px 2px rgb(108, 125, 150)',
      borderRadius: '2px',
    },
    Bow: Object.assign({}, styles.tableRow, {
      width: bowWidth,
    }),
    Stroke: Object.assign({}, styles.tableRow, { width: strokeWidth }),
    Start: Object.assign({}, styles.tableRow, {
      width: omitStart ? 0 : props.dimensions[TimeTemplate].width,
    }),
    RawTime: Object.assign({}, styles.tableRow, {
      width: omitRaw ? 0 : props.dimensions[TimeTemplate].width,
    }),
    Waypoint: Object.assign({}, styles.tableRow, {
      width: props.dimensions[TimeTemplate].width,
    }),
    Adjust: Object.assign({}, styles.tableRow, {
      // Add slightly more for pen/handicap notations
      width: omitAdjust ? 0 : 1.2 * props.dimensions[TimeTemplate].width,
    }),
    Time: Object.assign({}, styles.tableRow, {
      width: props.dimensions[TimeTemplate].width,
    }),
    Split: Object.assign({}, styles.tableRow, {
      width: props.dimensions[TimeTemplate].width / 2,
      fontSize: '0.8em',
    }),
  };

  if (omitStroke) rowStyle.Stroke.display = 'none';
  if (omitStart) rowStyle.Start.display = 'none';
  if (omitRaw) rowStyle.RawTime.display = 'none';
  if (omitSplit) rowStyle.Split.display = 'none';
  if (omitCrew) rowStyle.Crew.display = 'none';
  if (omitPlace) rowStyle.Place.display = 'none';
  if (omitAdjust) rowStyle.Adjust.display = 'none';

  const marginPx = `${tableMargin}px`;

  // clone rowStyle and add fontWeight bold
  const headerStyle: { [key: string]: React.CSSProperties } = {};
  Object.keys(rowStyle).forEach((item) => {
    headerStyle[item] = Object.assign({}, rowStyle[item], {
      fontWeight: 'bold',
      fontSize: '1em',
    });
  });

  let waypoints = Util.getResultWaypoints(regattaData, allWaypoints);
  if (omitRaw && omitCols >= 3) {
    waypoints = [];
  }

  const day = dayList.length > 1 ? `${data.Day || ''} ` : '';
  const startTime = data.Start;
  const showFinishTimes = editable || officialTimes || Boolean(!regattaData.ResultsPendOfficial);
  const totalCols = 9 + waypoints.length - omitCols;

  const onOfficialChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const officialEntries = Object.values(allOfficialEntries || {})
      .filter((e) => e.EventNum === eventNum)
      .sort((a, b) => {
        const t1 = Number(a.Timestamp || a.Time); // some old regattas didnt have Timestamp
        const t2 = Number(b.Timestamp || b.Time);

        // Note: This is a reverse sort!
        if (t1 < t2) {
          return 1;
        }
        if (t1 > t2) {
          return -1;
        }
        return 0;
      });

    // Use last undeleted item if present, otherwise use first array element
    let eventOfficial = officialEntries.find((e) => e[Names.N_STATE] !== Names.STATE_DELETED);
    if (!eventOfficial && officialEntries.length > 0) {
      eventOfficial = officialEntries[0];
    }
    if (!eventOfficial) {
      // create one
      const newLap = {};
      const uuid = uuidv1();
      const now = new Date();
      newLap[Names.N_UUID] = uuid;
      newLap[Names.N_REGATTA] = props.regatta;
      newLap[Names.N_GATE] = 'R';
      newLap[Names.N_EVENTNUM] = eventNum;
      newLap[Names.N_PENALTY_CODES] = Names.N_OFFICIAL;
      newLap[Names.N_STATE] = Names.STATE_DELETED;
      newLap[Names.N_TIME] = milliToString(
        (now.getTime() - now.getTimezoneOffset() * 60 * 1000) % (24 * 60 * 60 * 1000)
      );
      if (newLap[Names.N_TIMESTAMP] === undefined) {
        newLap[Names.N_TIMESTAMP] = now.getTime();
      }
      eventOfficial = newLap as unknown as Lap;
    }
    eventOfficial[Names.N_STATE] = checked ? 'OK' : Names.STATE_DELETED;
    setOfficialTimes(checked);
    Util.storeLap(props.regatta, eventOfficial).catch((e) => {
      console.log(String(e));
    });
  };
  // console.log(`Rendering Event ${eventNum}`);

  const eventName = isInfoEvent ? data.Event.substring(data.EventNum.length) : `${getTitle('Event')}: ${data.Event}`;
  const SearchableTableCell = searchText ? HighlightedTableCell : RegularTableCell;
  return (
    <div
      style={{
        pageBreakInside: props.tableNumber === 0 ? 'auto' : 'avoid',
        marginLeft: marginPx,
        marginRight: marginPx,
      }}
    >
      <Table style={{ tableLayout: 'auto' }}>
        <TableHead>
          <TableRow
            style={{ ...styles.headerRow, ...styles.tableSuperRow, ...(isInfoEvent ? styles.info : undefined) }}
          >
            <TableCell
              colSpan={editable ? 5 : totalCols - 2}
              style={{
                ...styles.tableSuperHeader,
                ...(tableWidth >= 800
                  ? {}
                  : { fontSize: '1em', lineHeight: '1em', paddingBottom: '4px', paddingTop: '4px' }),
              }}
            >
              <div>{`${day}${startTime}${startTime && ' '}${eventName}`}</div>
              {data.EventInfo && (
                <div
                  style={{
                    ...styles.tableEventInfo,
                    fontSize: `${
                      tableWidth >= 800 ? 1 : Math.min(1, Math.max(0.7, 1 - 30 / (data.EventInfo.length + 1)))
                    }em`,
                  }}
                >{`${data.EventInfo}`}</div>
              )}
            </TableCell>
            {isInfoEvent ? (
              <TableCell colSpan={totalCols - 2 - 4} style={styles.tableSuperHeader} />
            ) : editable ? (
              <>
                <TableCell colSpan={totalCols - 2 - 4} style={styles.tableSuperHeader}>
                  <TimeSystemSelection eventNum={data.EventNum} />
                </TableCell>
              </>
            ) : null}
            <TableCell colSpan={2} style={styles.tableSuperHeaderRight}>
              {isInfoEvent ? (
                <></>
              ) : editable && !finished ? (
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Switch checked={officialTimes} onChange={onOfficialChange} name="official" color="primary" />
                  }
                  label="Official"
                />
              ) : (
                <>
                  {provisionalTimes && <div>{getTitle('Provisional Times')}</div>}
                  {officialTimes && <div>{getTitle('Official')}</div>}
                </>
              )}
            </TableCell>
          </TableRow>
          {!isInfoEvent && (
            <TableRow style={styles.headerRow}>
              {editable && <TableCell />}
              <TableCell style={headerStyle.Place}>{getTitle('Place')}</TableCell>
              <TableCell style={headerStyle.Crew}>{getTitle('Crew')}</TableCell>
              <TableCell style={headerStyle.Bow}>{isSprint ? getTitle('Lane') : getTitle('Bow')}</TableCell>
              <TableCell style={headerStyle.Stroke}>{`${getTitle('Stroke/Cox')}`}</TableCell>
              <TableCell style={headerStyle.Start}>{getTitle('Start')}</TableCell>
              {waypoints.map((waypoint) => {
                return (
                  <TableCell key={'col_' + waypoint} style={headerStyle.Waypoint}>
                    {waypoint.replace(/_/g, ' ')}
                  </TableCell>
                );
              })}
              <TableCell style={headerStyle.RawTime}>{getTitle(editable ? 'Finish' : 'Raw Time')}</TableCell>
              <TableCell style={headerStyle.Adjust}>{getTitle('Adjust')}</TableCell>
              <TableCell style={headerStyle.Time}>{getTitle('Time')}</TableCell>
              <TableCell style={headerStyle.Split}>{getTitle('Split')}</TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {entries.map((row, i) => {
            let startTimeString = row[Names.N_START_TIME];
            let rawTimeString = row[Names.N_RAW_TIME];
            let progressTime = '';
            if (showProgress && rawTimeString === 'In Progress') {
              const startMilli = timeToMilli(startTimeString);
              const now = new Date();
              let nowMilli = now.getTime() - now.getTimezoneOffset() * 60 * 1000;
              nowMilli = nowMilli % (24 * 3600 * 1000);
              const delta = nowMilli - startMilli;
              progressTime = milliToString(delta, false, 1);
            }
            if (editable && showClockTimes) {
              rawTimeString = row.G_Finish_time_raw;
            } else if (editable && showStopwatchTimes) {
              rawTimeString = timeDiff(stopwatchConfig.clockTime, row.G_Finish_time_raw);
              startTimeString = timeDiff(stopwatchConfig.clockTime, startTimeString);
            } else if (rawTimeString === 'In Progress') {
              rawTimeString = getTitle('In Progress'); // translate
            } else if (!showFinishTimes) {
              rawTimeString = rawTimeString ? getTitle('Finished') : '';
            }

            let adjTimeString = row[Names.N_ADJ_TIME];
            if (adjTimeString === 'In Progress') {
              adjTimeString = getTitle('In Progress'); // translate
            } else if (!showFinishTimes && adjTimeString.match(/^-?\+?[0-9]/)) {
              adjTimeString = adjTimeString ? getTitle('Finished') : '';
            }

            const stroke = row[Names.N_STROKE] || '';
            const crew =
              omitStroke && stroke.length > 0 ? `${row[Names.N_CREW]} (${row[Names.N_STROKE]})` : row[Names.N_CREW];
            const key = `${row[Names.N_EVENTNUM]}-${row[Names.N_BOW]}-${row[Names.N_CREW]}`;
            const bgStyle: React.CSSProperties = {
              background: i % 2 ? evenColor : oddColor,
              ...styles.tableRow,
            };
            return (
              <TableRow key={key} style={bgStyle}>
                {editable && (
                  <TableCell onClick={onRowClick(row)}>
                    <EditIcon />
                  </TableCell>
                )}
                <TableCell style={rowStyle.Place}>{showFinishTimes && row[Names.N_PLACE]}</TableCell>
                <SearchableTableCell text={crew} style={rowStyle.Crew} />
                <TableCell style={rowStyle.Bow}>{row[Names.N_BOW]}</TableCell>
                <SearchableTableCell text={row[Names.N_STROKE]} style={rowStyle.Stroke} />
                <WrappedTableCell ts={startTimeString} wp="S" row={row} style={rowStyle.Start}>
                  {startTimeString}
                </WrappedTableCell>
                {waypoints.map((waypoint) => {
                  const wpkey = `G_${waypoint}_time`;
                  let time = showClockTimes || (showAllProp && row[wpkey] === '---') ? row[`${wpkey}_raw`] : row[wpkey];
                  if (showStopwatchTimes) {
                    time = timeDiff(stopwatchConfig.clockTime, row[`${wpkey}_raw`]);
                  }
                  let color: string | undefined;

                  if (showAnalysis) {
                    const isAlternate = waypoint.match(/[0-9]/);
                    if (isAlternate) {
                      //  Change G_waypoint1_time into G_waypoint_time_raw
                      const base = wpkey.replace(/[ _]*[0-9].*/, '_time_raw');
                      const baseTime = row[base];
                      const altTime = row[`${wpkey}_raw`];
                      if (altTime && baseTime) {
                        const diff = timeDiff(altTime, baseTime, false).replace(/^00:/, '').replace('-00:', '-');
                        if (!showClockTimes) {
                          time = diff;
                        }
                        const milli = Math.abs(timeToMilli(diff));
                        color = milli <= 700 ? '#0f08' : milli <= 1200 ? '#ff08' : '#f00c';
                      } else if (altTime) {
                        color = '#f0fa';
                      } else if (baseTime) {
                        color = '#8084';
                      }
                    }
                  }
                  return (
                    <WrappedTableCell
                      ts={time}
                      wp={waypoint}
                      row={row}
                      key={waypoint}
                      style={{ ...rowStyle.Waypoint, backgroundColor: color }}
                    >
                      {time}
                    </WrappedTableCell>
                  );
                })}
                <WrappedTableCell ts={rawTimeString} wp="F" row={row} style={rowStyle.RawTime}>
                  {rawTimeString}
                </WrappedTableCell>
                <TableCell style={rowStyle.Adjust}>{row[Names.N_PENALTY_CODES]}</TableCell>
                <TableCell style={rowStyle.Time}>{progressTime || adjTimeString}</TableCell>
                <TableCell style={rowStyle.Split}>{showFinishTimes && row[Names.N_SPLIT]}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default EventTable;
